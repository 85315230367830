<template>
  <div>
    <b-card class="mt-2">
      <validation-observer ref="questionRules">
        <b-row class="mb-2">
          <table class="table table-sm">
            <thead>
              <tr>
                <th scope="col">Questions</th>
              </tr>
            </thead>
          </table>
        </b-row>
        <b-row>
          <!-- Groups -->
          <b-col cols="12" xl="9" md="9" sm="12">
            <b-form-group label="Groups" label-for="groups">
              <validation-provider #default="{ errors }" name="group">
                <v-select
                  id="group"
                  v-model="questionData.group"
                  :taggable="true"
                  :autoscroll="true"
                  maxHeight="50"
                  :create-option="(temp) => ({ id: 0, groupName: temp })"
                  :loading="groupSelectBoxLoading"
                  @option:created="createNewGroupValue"
                  @option:selected="selectedGroupValue"
                  :reduce="(val) => val"
                  :options="groupData"
                  label="groupName"
                  :clearable="true"
                >
                </v-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <!-- Question Type -->
          <b-col cols="12" sm="12" md="3" xl="3">
            <b-form-group label="Question Type" label-for="questionType">
              <validation-provider name="type" #default="{ errors }">
                <v-select id="type" v-model="questionData.type" :options="questionTypes" :reduce="(val) => val" :filterable="false" label="name" :clearable="false" input-id="QuestionType" />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row class="mb-2">
          <!-- Add Question -->
          <b-col cols="12" xl="10" md="10" sm="12">
            <b-form-group>
              <validation-provider #default="{ errors }" name="title">
                <b-input-group>
                  <b-form-input id="title" v-model.lazy="questionData.title" type="text" placeholder="Add Question" :state="titleState" aria-describedby="input-live-feedback"></b-form-input>
                  <small class="text-danger">{{ errors[0] }}</small>
                  <b-form-invalid-feedback id="input-live-feedback">
                    Enter at least 5 letters
                  </b-form-invalid-feedback>
                </b-input-group>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12" xl="2" md="2" sm="12">
            <b-button block variant="outline-success" @click="addQuestion()">Add New Question +</b-button>
          </b-col>
        </b-row>
      </validation-observer>
      <hr />
      <b-row align-h="end">
        <b-col cols="6">
          <b-form-group class="mb-40">
            <b-input-group>
              <b-form-input id="filter-input" v-model="filter" type="search" placeholder="Type to Search"></b-form-input>
              <b-input-group-append>
                <b-button @click="filter = ''" variant="warning">Clear</b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>
      <b-table
        :items="questions"
        :small="true"
        responsive
        :bordered="true"
        :sticky-header="true"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        style="padding-bottom:30px"
        class="position-relative "
        head-variant="dark"
        :filter="filter"
        filter-debounce="250"
        :filter-included-fields="filterOn"
        @filtered="onFiltered"
        :fields="column"
        striped
        hover
        :current-page="currentPage"
        :per-page="perPage"
        show-empty
        empty-text="No matching records found"
      >
        <template v-slot:cell(questionType)="data">
          <!-- <template v-if="edit != item.id">{{ value }}</template> -->
          <!-- <b-form-input v-model="data.item.group" /> -->
          <span class="font-weight-bold">
            {{ data.item.type.status === 'A' ? 'Answer' : 'Comment' }}
          </span>
        </template>
        <template v-slot:cell(groups)="data">
          <!-- <template v-if="edit != item.id">{{ value }}</template> -->
          <!-- <b-form-input v-model="data.item.group" /> -->
          <span class="font-weight-bold">
            {{ data.item.group.groupName }}
          </span>
        </template>
        <template v-slot:cell()="data">
          <!-- <template v-if="edit != item.id">{{ value }}</template> -->
          <!-- <b-form-input v-model="data.item.question" /> -->
          <span class="font-weight-bold">
            {{ data.item.title }}
          </span>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">
            <template #button-content>
              <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
            </template>
            <b-dropdown-item @click="editQuestion(data.item)">
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">Edit Question </span>
            </b-dropdown-item>

            <b-dropdown-item @click="deleteQuestion(data.item.questionId)">
              <feather-icon icon="DeleteIcon" />
              <span class="align-middle ml-50">Delete Question</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>

      <div class="mx-2 mb-2">
        <b-row>
          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
          </b-col>

          <!-- Pagination -->
          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
            <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" first-number last-number class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
      <b-modal id="modal-prevent-closing" ref="modal" title="Edit Question" size="lg" v-model="modalShow" @hidden="resetModal" @ok="handleOk">
        <form ref="form" @submit.stop.prevent="handleSubmit">
          <validation-observer ref="editQuestionRules">
            <b-col cols="12" sm="12" md="12" xl="12">
              <b-form-group label="Question Type" label-for="EditQuestionType">
                <validation-provider name="EditQuestionType" #default="{ errors }">
                  <v-select id="EditQuestionType" v-model="editQuestionData.type" :options="questionTypes" :reduce="(val) => val" :filterable="false" label="name" :clearable="false" input-id="EditQuestionType" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="12" xl="12" md="12" sm="12">
              <b-form-group label="Group" label-for="editGroup">
                <validation-provider #default="{ errors }" name="editGroup">
                  <v-select
                    id="editGroup"
                    v-model="editQuestionData.group"
                    :taggable="true"
                    :autoscroll="true"
                    maxHeight="50"
                    :create-option="(temp) => ({ id: 0, groupName: temp })"
                    :loading="groupSelectBoxLoading"
                    @option:created="editGroupValue"
                    @option:selected="selectedGroupValue"
                    :reduce="(val) => val"
                    :options="groupData"
                    label="groupName"
                    :clearable="true"
                  >
                  </v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="12" xl="12" md="12" sm="12">
              <b-form-group label="Question" label-for="editQuestion">
                <validation-provider #default="{ errors }" name="editQuestion">
                  <b-input-group>
                    <b-form-textarea id="editQuestion" v-model.lazy="editQuestionData.title" type="text" rows="3" placeholder="Edit Question" :state="editTitleState" aria-describedby="input-live-feedback-edit" no-resize></b-form-textarea>
                    <small class="text-danger">{{ errors[0] }}</small>
                    <b-form-invalid-feedback id="input-live-feedback-edit">
                      Enter at least 5 letters
                    </b-form-invalid-feedback>
                  </b-input-group>
                </validation-provider>
              </b-form-group>
            </b-col>
          </validation-observer>
        </form>
      </b-modal>
    </b-card>
  </div>
</template>

<script>
import { BRow, BCol, BFormGroup, BFormInput } from 'bootstrap-vue';
import settingsStoreModule from '../settingsStoreModule';
import store from '@/store';
import { ref, onUnmounted } from '@vue/composition-api';
import vSelect from 'vue-select';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import _ from 'lodash';
import axiosIns from '@/libs/axios';

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    vSelect,
    //validation
    ValidationProvider,
    ValidationObserver,
  },
  setup() {
    const SETTINGS_APP_STORE_MODULE_NAME = 'settings';
    // Register module
    if (!store.hasModule(SETTINGS_APP_STORE_MODULE_NAME)) store.registerModule(SETTINGS_APP_STORE_MODULE_NAME, settingsStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(SETTINGS_APP_STORE_MODULE_NAME)) store.unregisterModule(SETTINGS_APP_STORE_MODULE_NAME);
    });
  },
  mounted() {
    this.getGroups();
  },
  data() {
    return {
      groupSelectBoxLoading: false,
      questionData: {
        title: '',
        type: { name: 'Answer', status: 'A' },
        group: {},
      },
      editQuestionData: {
        title: '',
        type: { name: 'Answer', status: 'A' },
        group: {},
      },
      groupData: [],
      questionTypes: [
        { name: 'Comment', status: 'B' },
        { name: 'Answer', status: 'A' },
      ],
      edit: null,
      newitem: '',
      filter: null,
      filterOn: [],
      modalShow: false,
      perPage: 10,
      questions: [],
      currentPage: 1,
      pageOptions: [5, 10, 15, 20],
      sortBy: 'question',
      sortDesc: false,
      totalRows: 0,
      column: [
        { key: 'questionType', sortable: true, label: 'Question Type' },
        { key: 'groups', sortable: true, label: 'Groups' },
        { key: 'question', sortable: true, label: 'Quesitons' },
        { key: 'actions', label: 'Actions' },
        // {
        //   key: "actions",
        //   label: "Actions",
        //   thClass: "text-right",
        //   tdClass: "text-right pr-0",
        // },
      ],
    };
  },
  computed: {
    titleState() {
      if (this.questionData.title === '') {
        return null;
      } else {
        return this.questionData.title.length > 5 ? true : false;
      }
    },
    editTitleState() {
      if (this.editQuestionData.title === '') {
        return null;
      } else {
        return this.editQuestionData.title.length > 5 ? true : false;
      }
    },
    dataMeta() {
      return {
        from: this.totalRows != 0 ? this.perPage * (this.currentPage - 1) + 1 : 0,
        to: this.perPage * (this.currentPage - 1) + Number(this.perPage) < this.totalRows ? this.perPage * (this.currentPage - 1) + Number(this.perPage) : this.totalRows,
        of: this.totalRows,
      };
    },
  },
  methods: {
    addQuestion() {
      this.$refs.questionRules.validate().then((success) => {
        if (success) {
        }
      });
      if (this.questionData.title !== '' && this.questionData.title.length > 5) {
        const newQuestion = {
          title: this.questionData.title,
          groupId: this.questionData.group.id,
          status: this.questionData.type.status ? this.questionData.type.status : 'A',
        };
        store
          .dispatch('settings/addNewQuestionItem', newQuestion)
          .then((res) => {
            if (res.status === 201) {
              this.getGroups();
              this.clearQuestionData();
              this.toastMessage('New Question');
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    createNewGroupValue(val) {
      this.groupSelectBoxLoading = true;

      if (this.groupData.find((x) => x.groupName.toUpperCase() == val.groupName.toUpperCase()) == undefined) {
        store
          .dispatch('settings/addNewGroupItem', {
            groupName: _.capitalize(val.groupName.trim()),
          })
          .then((response) => {
            if (response.status == 201) {
              //this.groupData.unshift(response.data);
              this.questionData.group = {};
              this.getGroups();
              this.toastMessage('New Group');
              this.groupSelectBoxLoading = false;
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        this.groupSelectBoxLoading = false;
      }
    },
    editGroupValue(val) {
      this.groupSelectBoxLoading = true;

      if (this.groupData.find((x) => x.groupName.toUpperCase() == val.groupName.toUpperCase()) == undefined) {
        store
          .dispatch('settings/addNewGroupItem', {
            groupName: _.capitalize(val.groupName.trim()),
          })
          .then((response) => {
            if (response.status == 201) {
              //this.groupData.unshift(response.data);
              this.questionData.group = {};
              this.getGroups();
              this.toastMessage('New Group');
              this.groupSelectBoxLoading = false;
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        this.groupSelectBoxLoading = false;
      }
    },
    getGroups() {
      this.groupSelectBoxLoading = true;
      store
        .dispatch('settings/fetchGroups', [])
        .then((res) => {
          this.questions = [];
          this.groupData = [];
          res.data.forEach((group) => {
            this.groupData.unshift({
              id: group.id,
              groupName: group.groupName,
              status: group.status,
            });

            if (group != null && group.questions.length > 0) {
              group.questions.forEach((data) => {
                this.questions.unshift({
                  id: group.id,
                  questionId: data.id,
                  type: { status: data.status },
                  group: { groupName: group.groupName },
                  title: data.title,
                });
              });
            }
            this.totalRows = this.questions.length;
          });
          this.groupSelectBoxLoading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    selectedGroupValue(val) {},
    editQuestion(question) {
      this.modalShow = true;
      this.editQuestionData = {
        id: question.questionId,
        title: question.title,
        group: question.group,
        type: question.type.status === 'A' ? { name: 'Answer', status: 'A' } : { name: 'Comment', status: 'B' },
      };
    },
    deleteQuestion(id) {
      const self = this;
      this.$swal({
        title: 'Are you sure you want to delete the question?',
        // text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
      }).then((result) => {
        if (result.isConfirmed) {
          store
            .dispatch('settings/deleteQuestion', id)
            .then((res) => {
              self.getGroups();
              self.$swal.fire('Deleted!', 'Question has been deleted.', 'success');
            })
            .catch((error) => {
              console.log(error);
            });
        }
      });
    },
    handleOk(bvModalEvent) {
      bvModalEvent.preventDefault();
      this.$refs.editQuestionRules.validate().then((success) => {
        if (success) {
          this.handleSubmit();
        }
      });
    },
    handleSubmit() {
      const editQuestionData = {
        id: this.editQuestionData.id,
        title: this.editQuestionData.title,
        groupId: this.editQuestionData.group.id,
        status: this.editQuestionData.type.status,
      };
      store
        .dispatch('settings/editQuestion', editQuestionData)
        .then((res) => {
          this.getGroups();
          this.resetModal();
          this.modalShow = false;
          this.editToastMessage('Question');
        })
        .catch((error) => {
          console.log(error);
        });
    },
    resetModal() {
      this.editQuestionData = {
        title: '',
        type: { name: 'Answer', status: 'A' },
        group: {},
      };
    },
    toastMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Successful',
          text: '✔️ ' + val + ' Added Successful',
          icon: 'ThumbsUpIcon',
          variant: 'success',
        },
      });
    },
    editToastMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Successful',
          text: '✔️ ' + val + ' Updated Successful',
          icon: 'CheckIcon',
          variant: 'success',
        },
      });
    },
    clearQuestionData() {
      this.questionData = {
        title: '',
        type: { name: 'Answer', status: 'A' },
        group: {},
      };
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
